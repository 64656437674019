
import { ref, defineComponent, computed } from 'vue'
import ButtonPrimary from '@/views/components/button/ButtonPrimary.vue'
import ButtonSecondary from '@/views/components/button/ButtonSecondary.vue'
import Modal from '@/views/components/modal/ModalDefault.vue'
import {  useRouter } from 'vue-router'
import environment from '@/configs/environtment'
import apiUseCase from '@/usecase/apiUseCase'

export default defineComponent({
  name: 'Dashboard',
  components: {
    ButtonPrimary,
    ButtonSecondary,
    Modal
  },
  setup() {
    const inputSearch = ref('')
    const showSearchModal = ref(false)
    const dataNotFound = ref(false)
    const router = useRouter()
    const inputError = ref(false)

    const isProduction = computed(() => environment.getMode() === 'production')
    
    const validateInput = () => {
      if (inputSearch.value.length < 3) {
        inputError.value = true
        return false
      }
      inputError.value = false
      return true
    }

    const executeSearch = () => {
      showSearchModal.value = true
      apiUseCase.get(`/openapi/v1/Orders?trackingNumber=${inputSearch.value}`)
        .then((response) => {
          if (!response.error && response.result.data.length > 0) {
            router.push({
              name: 'transaction-detail',
              params: { id: response.result.data[0].id },
              query: { from: 'dashboard' }
            })
          } else {
            dataNotFound.value = true
          }
        })
    }

    const onSearch = () => {
      if (validateInput()) {
        executeSearch()
      }
    }

    const onEnter = () => {
      if (validateInput()) {
        executeSearch()
      }
    }

    const hideSearchModal = () => {
      showSearchModal.value = false
      dataNotFound.value = false
    }

    return {
      inputSearch,
      showSearchModal,
      dataNotFound,
      isProduction,
      hideSearchModal,
      inputError,
      onEnter,
      onSearch,
    }
  }
})
